/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/Layout';
import Register from '../../components/global/Register';

export default function ({ data }) {
  const [device, setDevice] = useState('mobile');

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      footerMenu={data.footer_menu}
      menu={data.allMenuMenuLink}
      locations={data.locations.edges}
    >
      <main className="user-page login">
        <section className="grid-section grid-content-container">
          <h1 className="t-xlarge t-heading t-uppercase sub-section-title">
            Create New Account
          </h1>
          <div className="spacer"></div>
          <div className="columns">
            <div className="column is-8">
              <Register />
            </div>
          </div>
          <div className="spacer"></div>
        </section>
      </main>
    </Layout>
  );
};

export const UserRegisterQuery = graphql`
  query UserRegisterQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
  }
`;
